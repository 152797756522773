<template>
	<div class="mypage_content_wrap">
		<h2>나의 요금제 / 회원권 결제</h2>
		<div class="mypage_content_box_wrap">
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>나의 요금제</h4>
				</div>
				<div class="mypage_list_box">
					<ul class="tit_wrap2">
						<li class="bold line_h">나의 요금제</li>
						<li v-if="current_plan_rate !== ''" class="bold line_h">사용 시작일</li>
						<li v-else class="bold line_h">상품 결제하기</li>
					</ul>
					<ul class="info_wrap">
						<li class="line_h">{{ current_plan_title }}</li>
						<li v-if="current_plan_rate !== ''" class="line_h">
							{{ current_plan_rate }} ~ {{ current_plan_rate_end }}
						</li>
						<li
							v-if="current_plan_rate !== ''"
							@click="store.Refund_modal = true"
							style="color: red"
							class="cancel line_h"
						>
							구독 취소
						</li>
						<!-- <li @click="$router.push('/payments')" v-else class="change line_h">바로가기</li> -->
						<li @click="goPaymentsPage" v-else class="change line_h">바로가기</li>
					</ul>
				</div>
			</div>
			<div class="mypage_content_box">
				<div class="mypage_tit_wrap">
					<h4>나의 이용 내역</h4>
				</div>
				<div v-for="(item, index) in use_list" :key="index" class="mypage_list_box">
					<ul class="tit_wrap2">
						<li class="bold line_h">사용 요금제</li>
						<li class="bold line_h">사용 기간</li>
						<li class="bold line_h">상태</li>
					</ul>
					<ul class="info_wrap">
						<li class="line_h">{{ item.item_name }}</li>
						<li class="line_h">{{ item.charge_date }} ~ {{ item.use_end_date }}</li>
						<button style="cursor: default" type="button" class="circle_basic_btn circle_blue">만료</button>
					</ul>
				</div>
			</div>
		</div>
		<div class="pagenation_wrap">
			<ul class="pagenation" v-if="use_list">
				<Pagination @changePage="page_active" :page="page" :totalPage="totalPage"></Pagination>
			</ul>
		</div>
		<teleport to=".wrapper">
			<!-- <RealAudioPlayer></RealAudioPlayer>
		<TopPlayer v-show="playerVisible && playerPosition === 'top'"></TopPlayer>
		<BottomPlayer v-show="playerVisible && playerPosition === 'bottom'"></BottomPlayer> -->
			<div class="modal_wrap" v-if="modalActive">
				<div class="modal modal_mxc">
					<component :is="setModalComponent"></component>
				</div>
			</div>
		</teleport>
	</div>
</template>

<script setup>
import ajax from '@/api/api-manager';
import jwt from '@/api/LocalStorage';
import { userInfoStore } from '@/store/UserStore';
import Pagination from '@/shared-components/Pagination.vue';
import { computed, onMounted, ref } from 'vue';
import { getLoginInfo, checkLogin } from '@/common-methods/checkAccount';
import { useRouter } from 'vue-router';
import { contentModalStore } from '@/store/contentDetailStore';
import NotLogin from '@/components/content-detail/modal/NotLogin.vue';
import NotUseContent from '@/components/content-detail/modal/NotUseContent.vue';
import InsertSuccess from '@/components/content-detail/modal/InsertSuccess.vue';
import DeleteSuccess from '@/components/content-detail/modal/DeleteSuccess.vue';
import ModifySuccess from '@/components/content-detail/modal/ModifySuccess.vue';
import { storeToRefs } from 'pinia';

const current_plan_title = ref('사용중인 요금제가 없습니다.');
const current_plan_rate = ref('');
const current_plan_rate_end = ref('');
const page = ref(null);
const current_btn_valid = ref(1);
const store = userInfoStore();
const totalPage = ref(null);
const use_list = ref(null);
const loginState = ref(checkLogin());
const router = useRouter();
const modalStore = contentModalStore();

// 로그인 체크하고 진행
onMounted(() => {
	if (loginState.value) {
		currentUseMembership();
	}
});

const { modalActive, modalType } = storeToRefs(modalStore);

const goPaymentsPage = () => {
	if (loginState.value) {
		router.push('/payments');
	} else {
		modalStore.setModalActive(true);
		modalStore.setModalType('login');
		// alert('asdfasdf');
	}
};

// 나의 요금제 현황 조회
const currentUseMembership = () => {
	ajax('charge/use_current_plan').then((res) => {
		if (res.data.data !== null) {
			current_plan_title.value = res.data.data.item_name;
			current_plan_rate.value = res.data.data.charge_date;
			current_plan_rate_end.value = res.data.data.use_end_date;
		}
		use_list_patch();
	});
};

// 나의 이용 내역 조회
const use_list_patch = () => {
	const form = new FormData();
	form.append('page', current_btn_valid.value);
	ajax('charge/use_list', form).then((res) => {
		use_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};

// use_list_patch();

// 페이지 변경 시 작동
const page_active = (event) => {
	current_btn_valid.value = event;
	use_list_patch();
};

// 모달 컴포넌트 선택 ( Login 필요, 서비스 이용, 별점 및 댓글 등록 완료)
const setModalComponent = computed(() => {
	switch (modalType.value) {
		case 'login':
			return NotLogin;
		case 'service':
			return NotUseContent;
		case 'insert':
			return InsertSuccess;
		case 'delete':
			return DeleteSuccess;
		case 'modify':
			return ModifySuccess;
		default:
			return false;
	}
});
</script>
